<template>
  <div class="PersonalCenter">

    <Head></Head>
    <div class="main">
      <Introduce></Introduce>
      <div class="content fs">
        <ul class="tabTitle">
          <li v-for="(item, index) in tabTitle" :key="index" @click="tabChange(index)"
            :class="{ active: num == index }">
            {{ item }}
          </li>
        </ul>
        <div class="substance">
          <div v-show="num == 0 || num == 1" class="collection">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="已收藏" name="0">
                <div v-for="(items, index2) in collectionList" :key="index2">
                  <div v-for="(item2, index) in items.userDelivery" :key="index"
                    @click="$router.push('/indexDetails?id=' + item2.recruitId)" class="collectionBox collectionStyle">
                    <div class="left">
                      <p>
                        <b class="wz" :title='item2.jobName +
                        "【" +
                        item2.areaName +
                        "·" +
                        item2.address +
                        "】"'>{{
      item2.jobName +
      "【" +
      item2.areaName +
      "·" +
      item2.address +
      "】"
  }}</b><span>{{ item2.wageMax }}</span>
                      </p>
                      <p>
                        <span>{{ item2.companyExperienceName + "经验" }}</span><span>{{ item2.educationName }}</span>
                      </p>
                    </div>
                    <div class="right">
                      <div class="company">
                        <img :src="item2.enterpriseLogo" alt="" />
                      </div>
                      <div>
                        <p>
                          {{ item2.enterpriseName }}
                          <img src="@/assets/img/Index_JobSeeker/icon6.png" alt="" />
                        </p>
                        <p>{{ item2.recruitCount }}人</p>
                      </div>
                    </div>
                    <div class="end" @click.stop="collectionRemoveFn(item2.id)">
                      <p>取消</p>
                      <p>收藏</p>
                    </div>
                  </div>
                </div>
                <el-empty v-if="!(total > 0)" description="空空如也"></el-empty>
                <el-footer class="listPage" v-if="total > 0">
                  <el-pagination :total="total" :current-page.sync="collectionForm.pageNum"
                    :page-size.sync="collectionForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
                    @size-change="getCollectionList" @current-change="getCollectionList"></el-pagination>
                </el-footer>
              </el-tab-pane>
              <el-tab-pane label="已投递" name="1">
                <div v-for="(items, index2) in deliveryList" :key="index2">
                  <p class="time">
                    {{ items.fomTime }}
                  </p>
                  <div v-for="(item2, index) in items.userDelivery" :key="index"
                    @click="$router.push('/indexDetails?id=' + item2.recruitId)" class="deliveryBox">
                    <div class="top fcs">
                      {{ item2.jobName + '【' + item2.cityName + '·' + item2.areaName + '】' }}<span>{{ item2.wageMax }}</span>
                    </div>
                    <div class="bottom fcs">
                      <div class="left f">
                        <img class="company" :src="item2.enterpriseLogo" alt="" />
                        <ul>
                          <li>
                            {{ item2.enterpriseName }}
                            <img src="../../assets/img/Index_JobSeeker/icon6.png" alt="" />
                          </li>
                          <li>{{ item2.recruitCount }}人</li>
                        </ul>
                      </div>
                      <div class="right">{{ item2.deliveryTime }}</div>
                    </div>
                  </div>
                </div>
                <el-empty v-if="!(total > 0)" description="空空如也"></el-empty>
                <el-footer class="listPage" v-if="total > 0">
                  <el-pagination :total="total" :current-page.sync="deliveryListForm.pageNum"
                    :page-size.sync="deliveryListForm.pageSize" layout="total, sizes, prev, pager, next, jumper"
                    @size-change="getDeliveryList" @current-change="getDeliveryList"></el-pagination>
                </el-footer>
              </el-tab-pane>
            </el-tabs>

          </div>
          <div v-show="num == 3"></div>
          <div v-show="num == 4" class="changePassword">
            <p class="title">修改密码</p>
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
              class="demo-ruleForm" label-position="top">
              <el-form-item label="当前密码" prop="currentPass">
                <el-input v-model="ruleForm.currentPass" type="password" placeholder="请输入当前密码"></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="pass">
                <el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="请输入新密码"></el-input>
                <p class="tips"><span>*</span>密码至少6位，包含字母和数字</p>
              </el-form-item>
              <el-form-item label="确认密码" prop="checkPass">
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" placeholder="请再次输入新密码">
                </el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">确认修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Introduce from "@/components/Introduce.vue";
import { collectionList, collectionRemove } from "@/api/index";
import { deliveryList } from "@/api/personalCenter";
import { changePwd } from "@/api/zp";

export default {
  name: "PersonalCenter",
  components: {
    Head,
    Footer,
    Introduce,
  },
  data() {
    var checkCurrentPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("当前密码不能为空！"));
      }
      return callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      } else {
        if (value.length < 6 || !/\d/.test(value) || !/[a-z|A-Z]/.test(value)) {
          return callback(new Error("密码至少6位，必须包含字母和数字！"));
        }
        return callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      num: 0, //默认显示的tab
      tabTitle: ["收藏职位", "我的投递", "我的简历", "消息", "修改密码"],
      tabTitle2: ["收藏", "已投递"],
      activeName: "0",
      requires: ["1-3年经验", "本科", "20岁以上"],
      ruleForm: {
        pass: "",
        checkPass: "",
        currentPass: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        currentPass: [{ validator: checkCurrentPass, trigger: "blur" }],
      },
      collectionForm: {
        pageNum: 1,
        pageSize: 9,
      },
      collectionList: [],
      deliveryList: [],
      deliveryListForm: {
        pageNum: 1,
        pageSize: 9,
      },
      total: 0,
    };
  },
  mounted() {
    this.getCollectionList();
  },
  methods: {
    // 自定义tab切换
    tabChange(i) {
      this.num = i;
      if (i == 1) {
        this.activeName = "1";
        this.handleClick({ index: 1 });
        this.getDeliveryList();
      } else if (i == 0) {
        this.activeName = "0";
        this.handleClick({ index: 0 });
        this.getCollectionList();
      } else if (i == 2) {
        this.$router.push("/myResume");
      } else if (i == 3) {
        this.$router.push("/chart");
      }
    },
    // tab切换
    handleClick(tab) {
      if (tab.index == 0) {
        this.num = 0;
        this.getCollectionList();
      } else {
        this.num = 1;
        this.getDeliveryList();
      }
    },
    // 修改密码
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          changePwd({
            oldPassword: this.ruleForm.currentPass,
            newPassword: this.ruleForm.pass,
          }).then(() => {
            this.$message.success("修改成功");
            this.$refs[formName].resetFields();
            sessionStorage.removeItem("userInfo");
            this.$router.push({ path: "/login" });
          });
        } else {
          return false;
        }
      });
    },

    // 获取收藏列表
    getCollectionList() {
      let arr = [];
      collectionList(this.collectionForm).then((res) => {
        this.collectionList = res.rows;
        res.rows.forEach((item) => {
          arr.push({
            fomTime: "收藏",
            userDelivery: [item],
          });
          this.collectionList = arr;
        });
        this.total = res.total;
      });
    },

    // 取消收藏
    collectionRemoveFn(id) {
      collectionRemove([id]).then((res) => {
        this.getCollectionList();
        this.$message({
          message: res.msg,
          type: "success",
        });
      });
    },

    // 获取投递列表
    getDeliveryList() {
      this.collectionList = [];
      deliveryList(this.deliveryListForm).then((res) => {
        this.deliveryList = res.rows;
        this.total = res.total;
      });
    },
  },
};
</script>
<style scoped>
.PersonalCenter {
  width: 100%;
  height: 100%;
}

.PersonalCenter .main {
  min-height: calc(100% - 278px);
}

.PersonalCenter .content {
  margin-bottom: 110px;
}

.PersonalCenter .tabTitle {
  padding: 0 16px;
  width: 200px;
  background: #ffffff;
  height: 490px;
}

.PersonalCenter .tabTitle li {
  height: 98px;
  line-height: 98px;
  border-bottom: 1px solid #eeeeee;
  font-size: 18px;
  color: #333;
  text-align: center;
}

.PersonalCenter .tabTitle li:last-child {
  border-bottom: none;
}

.PersonalCenter .tabTitle li.active {
  font-weight: bold;
  color: #0780e6;
  position: relative;
}

.PersonalCenter .tabTitle li.active::before {
  width: 6px;
  height: 98px;
  background: linear-gradient(180deg, rgba(124, 195, 255, 0) 0%, #3580df 100%);
  content: "";
  display: inline-block;
  position: absolute;
  left: -16px;
}

.PersonalCenter .substance {
  width: 980px;
}

.PersonalCenter /deep/ .el-tabs__nav-scroll {
  background: #ffffff;
}

.PersonalCenter /deep/ .el-tabs__item {
  height: 98px;
  line-height: 98px;
  font-size: 18px;
  color: #666666;
  padding: 0 100px 0 20px !important;
}

.PersonalCenter /deep/ .el-tabs__item.is-active {
  color: #0780e6;
}

.PersonalCenter /deep/ .el-tabs__active-bar {
  height: 4px;
  color: #0780e6;
}

.PersonalCenter /deep/ .el-tabs__header {
  margin-bottom: 0;
}

.PersonalCenter .collection .time {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-top: 24px;
  padding-left: 16px;
  padding: 0;
}

.PersonalCenter .collection .collectionBox {
  height: 117px;
  background: #ffffff;
  padding: 24px 16px;
  display: flex;
  margin-top: 20px;
  position: relative;
}

.PersonalCenter .collection .collectionBox .left {
  width: 475px;
  border-right: 1px solid #eeeeee;
}

.PersonalCenter .collection .collectionBox .left p:nth-child(1) {
  height: 25px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
  margin-bottom: 24px;
  display: flex;
}

.PersonalCenter .collection .collectionBox .left p:nth-child(1) .wz {
  white-space: nowrap;
  overflow: hidden;
  max-width: 350px;
  text-overflow: ellipsis;
}

.PersonalCenter .collection .collectionBox .left p:nth-child(1) span {
  color: #ff2647;
  margin-left: 36px;
}

.PersonalCenter .collection .collectionBox .left p:nth-child(2) {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}

.PersonalCenter .collection .collectionBox .left p:nth-child(2) span {
  padding: 0 16px;
  border-right: 1px solid #999999;
}

.PersonalCenter .collection .collectionBox .left p:nth-child(2) span:nth-child(1) {
  padding-left: 0;
}

.PersonalCenter .collection .collectionBox .left p:nth-child(2) span:last-child {
  padding-right: 0;
  border-right: none;
}

.PersonalCenter .collection .collectionBox .right {
  padding-left: 60px;
  display: flex;
}

.PersonalCenter .collection .collectionBox .company {
  width: 67px;
  height: 67px;
  margin-right: 16px;
}

.PersonalCenter .collection .collectionBox .company img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.PersonalCenter .collection .collectionBox .right p:nth-child(1) {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  line-height: 22px;
  margin-bottom: 25px;
}

.PersonalCenter .collection .collectionBox .right p:nth-child(1) img {
  vertical-align: sub;
  width: 18px;
  height: 18px;
}

.PersonalCenter .collection .collectionBox .right p:nth-child(2) {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}

.PersonalCenter .collection .collectionBox .end {
  /*display: none;*/
  width: 91px;
  height: 117px;
  background: linear-gradient(127deg, #7cc3ff 0%, #3580df 100%);
  border-radius: 16px 0px 0px 16px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  box-sizing: border-box;
  padding: 34px 27px;
  cursor: pointer;
}

/*.PersonalCenter .collection .collectionStyle:hover .end {*/
/*  display: block;*/
/*}*/
.PersonalCenter .collection .deliveryBox {
  width: 980px;
  padding: 16px;
  background: #ffffff;
  margin-top: 20px;
}

.PersonalCenter .collection .deliveryBox .top {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 16px;
}

.PersonalCenter .collection .deliveryBox .top span {
  color: #FF2647;
}

.PersonalCenter .collection .deliveryBox .bottom .left .company {
  width: 52px;
  height: 52px;
  border-radius: 4px;
}

.PersonalCenter .collection .deliveryBox .bottom .left ul {
  margin-left: 7px;
}

.PersonalCenter .collection .deliveryBox .bottom .left li img {
  vertical-align: text-bottom;
  width: 18px;
  height: 18px;
}

.PersonalCenter .collection .deliveryBox .bottom .left li:nth-child(1) {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  line-height: 22px;
  margin-bottom: 10px;
}

.PersonalCenter .collection .deliveryBox .bottom .left li:nth-child(2) {
  height: 20px;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
}

.PersonalCenter .collection .deliveryBox .right {
  height: 18px;
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}

.PersonalCenter .changePassword {
  height: 490px;
  background: #ffffff;
  padding: 24px 16px;
}

.PersonalCenter .changePassword .title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 16px;
}

.PersonalCenter .changePassword /deep/ .el-form-item__label {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-bottom: 8px;
  padding: 0;
}

.PersonalCenter .changePassword /deep/ .el-input__inner {
  width: 276px;
  height: 38px;
  border: 1px solid #cccccc;
  border-radius: 0;
}

.PersonalCenter .changePassword /deep/ .el-form-item {
  margin-bottom: 20px;
  position: relative;
}

.PersonalCenter .changePassword /deep/ .el-button {
  width: 90px;
  height: 34px;
  background: #0780e6;
  border-radius: 0;
  padding: 0;
  line-height: 34px;
  margin-top: 20px;
}

.PersonalCenter .changePassword .tips {
  position: absolute;
  top: 0;
  left: 296px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.PersonalCenter .changePassword .tips span {
  color: #ff2647;
}

.PersonalCenter .listPage .el-pagination {
  margin-top: 20px;
}
</style>
